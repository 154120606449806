import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slideimg from './assets/images/slider2.jpg';
import roler from './assets/images/roler.png';
import slideimg2 from './assets/images/bg.jpg'
// import { BsChevronLeft } from "react-icons/bs";
import ReactCompareImage from "react-compare-image";


function ImgCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
            <Carousel.Item >
                <div className='carousel_img'  >
                    <div className='d-block carousel_img_size' >
                        <ReactCompareImage
                            hover={true}
                            vertical={false}
                            leftImage={slideimg}
                            rightImage={slideimg2}
                        />
                    </div>
                    <div className='carousel_con'>

                        <div className='fs_1 ff_bold primary_color' >

                            Fensterreinigung innen und außen inkl. Rahmen u
                        </div>
                        <div className='cont_grid' >
                            <div className='price'  >
                                <div className='d_center' >
                                    <img src={roler} alt='' className='roler_img mb-3' ></img>
                                    <div className='' >
                                       Fensterputzen 16 Fensterflügel 3h Putzzeit
                                        <div>
                                            <strong>
                                                127.95 €
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d_xl_none' >
                                <div className='discrip' >
                                    Vor dem Einzug in die neue Wohnung musste alles ordentlich und professionell gereinigt werden.
                                    Neben Bad, Boden und Türen kamen nach dem Neubau auch diese Fenster dazu.
                                    Der Baustaub der letzten Monate musste aus den Fugen und Falzen entfernt werden.
                                    Danach war alles sauber ohne Schlieren.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </Carousel.Item>
            <Carousel.Item>
                <div className='carousel_img'  >
                <div className='d-block carousel_img_size' >
                        <ReactCompareImage
                            hover={true}
                            vertical={false}
                            leftImage={slideimg2}
                            rightImage={slideimg}
                        />
                    </div>
                     {/* <img
                        className="d-block carousel_img_size"
                        src={slideimg}
                        alt=""
                    /> */}
                    <div className='carousel_con'>

                        <div className='fs_1 ff_bold primary_color' >

                            Fensterreinigung innen und außen inkl. Rahmen u
                        </div>
                        <div className='cont_grid' >
                            <div className='price'  >
                                <div className='d_center' >
                                    <img src={roler} alt='' className='roler_img mb-3' ></img>
                                    <div className='' >
                                       Fensterputzen 16 Fensterflügel 3h Putzzeit
                                        <div>
                                            <strong>
                                                127.95 €
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d_xl_none' >
                                <div className='discrip' >
                                    Vor dem Einzug in die neue Wohnung musste alles ordentlich und professionell gereinigt werden.
                                    Neben Bad, Boden und Türen kamen nach dem Neubau auch diese Fenster dazu.
                                    Der Baustaub der letzten Monate musste aus den Fugen und Falzen entfernt werden.
                                    Danach war alles sauber ohne Schlieren.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </Carousel.Item>

        </Carousel>
    );
}

export default ImgCarousel;