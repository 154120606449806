import React from 'react'
import { Container } from 'react-bootstrap'
import JMNavbar from './navbar2'
import CleanerFooter from './footer'

const HelpCenter = () => {
    return (
        <div>
            <JMNavbar></JMNavbar>
            <Container className='my-5 about_list'  >
                <h3 className='ff-bold' >ANLEITUNG</h3>
                <p>
                    So geht's - Ihr Leitfaden für unsere Reinigungsdienstleistungen
                </p>

                <p>
                    Unsere Reinigungsdienstleistungen sind einfach zu buchen und stressfrei zu erleben. Hier sind fünf einfache Schritte, um unseren Service zu nutzen:
                </p>

                <ol className='my-3' >
                    <li>
                        <strong> Kontaktieren Sie uns:</strong> Rufen Sie uns an oder füllen Sie unser Kontaktformular auf unserer Website aus, um einen Termin für eine kostenlose Vor-Ort-Besichtigung zu vereinbaren.
                    </li>
                    <li>
                        <strong> Besichtigung:</strong> Wir besuchen Sie vor Ort und erstellen ein individuelles Angebot für Sie, das auf Ihre Bedürfnisse und Anforderungen abgestimmt ist.
                    </li>
                    <li>
                        <strong> Buchung:</strong> Wenn Sie mit unserem Angebot zufrieden sind, können Sie uns einfach mitteilen, dass Sie unsere Dienstleistungen buchen möchten. Wir werden dann einen Termin für die Reinigung vereinbaren.
                    </li>
                    <li>
                        <strong> Reinigung:</strong> Unsere professionellen Reinigungskräfte kommen zu Ihnen nach Hause oder ins Büro und führen die vereinbarten Reinigungsarbeiten durch.
                    </li>
                    <li>
                        <strong> Feedback: </strong>  Wir hören gerne von Ihnen! Wenn die Reinigung abgeschlossen ist, werden wir uns bei Ihnen melden, um sicherzustellen, dass Sie mit dem Ergebnis zufrieden sind. Wenn Sie Verbesserungsvorschläge haben oder Feedback geben möchten, sind wir immer offen für Anregungen.
                    </li>
                </ol>
                <p>
                    Egal, ob Sie eine einmalige Reinigung oder regelmäßige Reinigungen benötigen, unsere Schritte machen es einfach und unkompliziert, unseren Reinigungsservice zu buchen und zu erleben. Kontaktieren Sie uns noch heute und lassen Sie uns Ihnen helfen, eine saubere und gesunde Umgebung zu schaffen!
                </p>

            </Container>
            <CleanerFooter/>
        </div>
    )
}

export default HelpCenter