import React from 'react'
import JMNavbar from './navbar2'
import CleanerFooter from './footer'

const DataProtection = () => {
    return (
        <div>
            <JMNavbar />
            <div className="container my-5">
                <div className="row justify-content-center ">
                    <div className="col-md-12">
                        <div className="row justify-content-center">

                            <div className="col ps-sm-5 contact_Us py-4">
                                <h5 className='ff_bold' >
                                    Datenschutzerklärung
                                </h5>
                                <h6 >
                                    Wir freuen uns über Ihr Interesse an unserer Webseite. Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Nachfolgend möchten wir Sie ausführlich über den Umgang mit Ihren Daten informieren.
                                </h6>
                                <h5 className='ff_bold mt-4' >
                                    Verantwortliche Stelle:
                                </h5>
                                <h6>
                                    Verantwortliche Stelle im Sinne der Datenschutzgesetze ist Joan Mohammad, Ewald-Hanstein-Str. 7, 28755 Bremen.
                                </h6>

                                <h5 className='ff_bold mt-4' >
                                    Erhebung, Verarbeitung und Nutzung Ihrer Daten:
                                </h5>

                                <h6>
                                    Personenbezogene Daten werden nur erhoben, wenn Sie uns diese im Rahmen Ihrer Bestellung oder bei Eröffnung eines Kundenkontos oder Registrierung für unseren Newsletter freiwillig mitteilen. Wir verwenden die von Ihnen mitgeteilten Daten ohne Ihre gesonderte Einwilligung ausschließlich zur Erfüllung und Abwicklung Ihrer Bestellung.
                                    <br></br>
                                    Bei Anmeldung zum Newsletter wird Ihre E-Mail-Adresse für eigene Werbezwecke genutzt, bis Sie sich vom Newsletter abmelden. Die Abmeldung ist jederzeit möglich.
                                </h6>

                                <h5 className='ff_bold' >
                                    Weitergabe personenbezogener Daten:
                                </h5>
                                <h6>
                                    Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses benötigen. In diesen Fällen beachten wir strikt die Vorgaben des Bundesdatenschutzgesetzes. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.

                                </h6>
                                <div>
                                    <h5 className='ff_bold mb-4' >
                                        Auskunftsrecht:
                                    </h5>
                                    <p>
                                        Nach dem Bundesdatenschutzgesetz haben Sie ein Recht auf unentgeltliche Auskunft über Ihre gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <CleanerFooter />
        </div>
    )
}

export default DataProtection