import React from 'react'
import { Accordion } from 'react-bootstrap'
import { AiFillCaretRight } from 'react-icons/ai'

const AdditionalInfo = () => {
    return (
        <div className='additional_info' >
            <div className='row ' >
                <div className='col-lg-4 col-md-5 col-12' >
                    <div className='service_info' >
                        <div className='add_info' >
                            Weitere Informationen:
                        </div>
                        <AiFillCaretRight className='add_icon' />
                    </div>
                </div>
                <div className='col-lg-8 col-md-7 col-12' >
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Welche Reinigungsdienstleistungen bieten Sie an?</Accordion.Header>
                            <Accordion.Body>
                                <strong>
                                    Antwort:</strong> Wir bieten eine breite Palette von Reinigungsdienstleistungen an,
                                darunter Wohnungsreinigung, Fensterreinigung, Teppichreinigung, Polsterreinigung, Hausreinigung, Baureinigung, Umzugsreinigung, Endreinigung, Frühjahrsputz, Messie-Reinigung, Taubenkot-Entfernung, Gastronomiereinigung, Teppichwäscherei, Wasserschadenreinigung, Wintergartenreinigung, Nikotingeruchentfernung, Brandreinigung und Entrümpelung.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Wie viel kostet Ihre Reinigungsdienstleistung?</Accordion.Header>
                            <Accordion.Body>
                                <strong> Antwort:</strong> Der Preis hängt von der Art und Größe der zu reinigenden Fläche,
                                der Häufigkeit der Reinigung und anderen Faktoren ab. Wir bieten eine kostenlose Vor-Ort-Besichtigung und ein individuelles Angebot an, das auf Ihre Bedürfnisse zugeschnitten ist.

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Sind Ihre Reinigungsmittel umweltfreundlich?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Ja, wir verwenden nur umweltfreundliche Reinigungsmittel und moderne Reinigungstechnologien,
                                um sicherzustellen, dass unsere Arbeit sowohl sicher als auch nachhaltig ist.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Wie oft sollten wir unsere Räumlichkeiten reinigen lassen?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Die Häufigkeit der Reinigung hängt von der Art und Größe der zu reinigenden Fläche, dem Grad der Nutzung und anderen Faktoren ab. Wir bieten eine kostenlose Vor-Ort-Besichtigung und beraten Sie gerne darüber,
                                wie oft Ihre Räumlichkeiten gereinigt werden sollten.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Wie lange dauert eine Reinigung?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Die Dauer der Reinigung hängt von der Art und Größe der zu reinigenden Fläche,
                                dem Grad der Verschmutzung und anderen Faktoren ab. Wir bieten eine kostenlose Vor-Ort-Besichtigung und geben Ihnen eine genaue Zeitangabe, wie lange die Reinigung voraussichtlich dauern wird.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Können Sie auch außerhalb Ihrer regulären Arbeitszeiten reinigen?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Ja, wir bieten flexible Arbeitszeiten und können auch außerhalb unserer regulären Geschäftszeiten arbeiten, um sicherzustellen, dass Ihre Reinigungsbedürfnisse erfüllt werden.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Wie können wir mit Ihnen zusammenarbeiten?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Sie können uns telefonisch oder per E-Mail kontaktieren, um einen Termin für eine kostenlose Vor-Ort-Besichtigung zu vereinbaren. Wir werden Ihnen dann ein individuelles Angebot unterbreiten und die Details der Zusammenarbeit besprechen.</Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Wie stellen Sie sicher, dass die Vertraulichkeit unserer Daten und unseres Eigentums gewahrt bleibt?</Accordion.Header>
                            <Accordion.Body>
                                <strong>Antwort:</strong>
                                Wir legen großen Wert auf die Vertraulichkeit unserer Arbeit und sorgen dafür, dass alle unsere Mitarbeiter hohen ethischen Standards entsprechen. Wir arbeiten diskret und respektieren Ihre Privatsphäre, um sicherzustellen, dass Sie sich jederzeit wohl und sicher fühlen.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default AdditionalInfo
