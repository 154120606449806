import React from 'react'
import { Navbar, Container } from 'react-bootstrap'
import './css/style.css'
import curve from './assets/images/curve.svg';
import logo1 from './assets/logo/logo3.png';

import { Link } from 'react-router-dom';

const JMNavbar = () => {
    return (
        <div>
            <div className='' >
                <Navbar className='NavB mb-0'  style={{zIndex:1024}} >
                    <Container className='pb-0' >
                        <Navbar.Brand href="" className='pb-0' >
                           <Link to='/' >
                           <img src={logo1} alt='' className=' logo_size2' /></Link>
                        </Navbar.Brand>
                        
                    </Container>
                </Navbar>
                <div className='position-relative' style={{marginTop:"-5px"}} >
                    <Container className="p-0 d-flex align-items-center justify-content-between">
                        <div className=' Nav2 pe-5 mt-0' id='navbar2'  > <span className='bg_color' >Professionelle Reinigung seit 2022 </span>
                            <img src={curve} alt="" className='c_img ' />
                        </div>
                        <div className='sub' >.</div>
                    </Container>
                </div>
            </div>

        </div >
    )
}

export default JMNavbar
