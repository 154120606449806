import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';

const Cookies = () => {
    const [show, setShow] = useState(false);
    const [check, checkShow] = useState(false);
    useEffect(() => {
       let check = window.localStorage.getItem('see')
        if(check !== 'true'){
            checkShow(false)
            setShow(true)
        }if(check === 'true'){
            checkShow(true)
        }
    }, [])

    const accept = () => {
        window.localStorage.setItem('see','true')
        setShow(false)
    }

    return (
       
        <div className='cookies' >
            {
            check === false ?
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-100w"
                size='xl'
                className='cookyModal'
                aria-labelledby="cookiesModal"
                centered
            >
                <Modal.Header closeButton className='py-1' >
                    <Modal.Title id="cookiesModal"  >
                        Cookies
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-1' >
                        Wir verwenden auf unserer Webseite Cookies. Cookies sind kleine Textdateien,
                        die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie dienen dazu,
                        unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
                        Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser zu erkennen und Ihnen Services anzubieten. Cookies enthalten keine personenbezogenen Daten.
                    </p>
                    <p className='mt-1 mb-1' >Änderungen dieser Datenschutzerklärung

                    </p>
                    <p className='mt-1 mb-1' >
                        Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
                        unter Beachtung der geltenden Datenschutzvorschriften zu ändern.
                    </p>
                </Modal.Body>
                <Modal.Footer className='py-1 border-top-0'>
                    <Button variant='success' className='me-3' onClick={() => accept() } >
                        Accept
                    </Button>
                    <Button variant='light' onClick={() => setShow(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
             :null}

        </div>

    )
}

export default Cookies


