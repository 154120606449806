import React from 'react'
import JMNavbar from './navbar2'
import { Container } from 'react-bootstrap'
import CleanerFooter from './footer'

const MinRequirment = () => {
    return (
        <div>
            <JMNavbar></JMNavbar>
            <Container className='about_list my-5' >
                <h3 className='ff-bold'  >
                    UNSERE MINDESTANFORDERUNGEN

                </h3>
                <p>     Unsere Mindestanforderungen und Angebote</p>
                <ul>

                    <li>
                        Wir möchten unseren Kunden einen zuverlässigen und effektiven Reinigungsservice bieten. Um sicherzustellen, dass wir diesen Standard erfüllen können, haben wir einige Mindestanforderungen für unsere Dienstleistungen festgelegt.

                    </li>
                    <li>
                        Für Teppichreinigung und Polsterreinigung haben wir eine Mindestabnahme von 10 Quadratmetern Teppich und 2 Polstersitzen pro Auftrag. Für die Grundreinigung und Fensterreinigung haben wir eine Mindestdauer von 2 Stunden pro Auftrag.

                    </li>
                    <li>
                        Um unseren Kunden einen zusätzlichen Vorteil zu bieten, bieten wir keine Anfahrtskosten für Kunden an, die unsere Mindestanforderungen erfüllen.

                    </li>
                    <li>
                        Wir bieten auch spezielle Angebote für regelmäßige Reinigungsdienstleistungen an. Kontaktieren Sie uns, um weitere Informationen zu erhalten und ein individuelles Angebot zu erhalten.

                    </li>
                    Wir sind stolz darauf, einen professionellen Reinigungsservice anzubieten und arbeiten hart daran, sicherzustellen, dass unsere Kunden zufrieden sind. Kontaktieren Sie uns noch heute, um Ihre Reinigungsbedürfnisse zu besprechen und ein individuelles Angebot zu erhalten.
                    <li>
                        Wir sind stolz darauf, einen professionellen Reinigungsservice anzubieten und arbeiten hart daran, sicherzustellen, dass unsere Kunden zufrieden sind. Kontaktieren Sie uns noch heute, um Ihre Reinigungsbedürfnisse zu besprechen und ein individuelles Angebot zu erhalten.

                    </li>
                </ul>
            </Container>
            <CleanerFooter />
        </div>
    )
}

export default MinRequirment