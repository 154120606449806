import React from 'react'
import JMNavbar from './navbar2'
import { Container } from 'react-bootstrap'
import CleanerFooter from './footer'

const QualityClaim = () => {
    return (
        <div>
            <JMNavbar />
            <Container className='my-5' >
                <h3 className='ff-bold' >
                    Qualitätsansprüche
                </h3>
                <p className='my-3' >
                    Wir legen großen Wert auf Qualität und setzen auf geschultes Personal, um sicherzustellen, dass unsere Kunden den besten Service erhalten. Jede Fläche erfordert eine individuelle Reinigungsmethode, daher verwenden wir für jede Fläche spezielle Tücher und Mittel, um beste Reinigungsergebnisse zu erzielen.
                </p>
                <p className='my-3' >
                    Unser Team besteht aus sorgfältig ausgewählten Mitarbeitern, die regelmäßig geschult und trainiert werden, um sicherzustellen, dass sie immer auf dem neuesten Stand sind und die besten Reinigungstechniken und -methoden kennen. Wir stellen sicher, dass jedes Mitglied unseres Teams über die notwendigen Kenntnisse verfügt, um eine gründliche und effektive Reinigung durchzuführen.
                </p>
                <p className='my-3' >
                    Unser Personal ist auch darauf geschult, umweltbewusste Reinigungsmittel und -technologien zu verwenden, um sicherzustellen, dass unsere Arbeit nicht nur sicher, sondern auch nachhaltig ist. Wir arbeiten hart daran, unsere Umweltbelastung zu reduzieren und die Gesundheit unserer Kunden und unserer Mitarbeiter zu schützen.
                </p>
                <p className='my-3' >
                    Unser Ziel ist es, unseren Kunden immer den besten Service zu bieten, und wir sind stolz darauf, spezielle Tücher und Mittel für jede Fläche zu verwenden, um das beste Reinigungsergebnis zu erzielen. Wir sind bestrebt, unseren hohen Qualitätsstandard aufrechtzuerhalten und unseren Kunden einen hervorragenden Service zu bieten. Kontaktieren Sie uns noch heute, um Ihre Reinigungsbedürfnisse zu besprechen und ein individuelles Angebot zu erhalten.
                </p>
            </Container>
            <CleanerFooter />
        </div>
    )
}

export default QualityClaim