import React from 'react'
import JMNavbar from './navbar2'
import CleanerFooter from './footer'

const Imprint = () => {
    return (
        <div>
            <JMNavbar />
            <div className="container my-5">
                <div className="row justify-content-center ">
                    <div className="col-md-12">
                        <div className="row justify-content-center">

                            <div className="col ps-sm-5 contact_Us py-4">
                                <h5 className='ff_bold' >
                                    Angaben gemäß § 5 TMG:
                                </h5>
                                <h6 >
                                    Ewald-Hanstein-Str. 7
                                    <br></br>
                                    28755 Bremen
                                </h6>
                                <h5 className='ff_bold mt-4' >
                                    Kontakt:
                                </h5>
                                <h6>
                                    <strong> Telefon: </strong>  <a href="tel:042146888702" style={{textDecoration: 'none'}} className='text-dark'>042146888702</a> 
                                    <h6>
                                        <strong> E-Mail:</strong>  info@jmdienst.de
                                    </h6>
                                </h6>

                                <h5 className='ff_bold mt-4' >
                                    Umsatzsteuer-ID:
                                </h5>

                                <h6>
                                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz
                                    <h6>
                                        <strong>USt-ID:  </strong> DE269812246
                                    </h6>
                                </h6>

                                <h5 className='ff_bold' >
                                    Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                                </h5>
                                <h6>
                                    Joan Mohammad
                                    <h6>
                                        Ewald-Hanstein-Str. 7
                                        <br></br>
                                        28755 Bremen
                                    </h6>
                                </h6>
                                <div>
                                    <h5 className='ff_bold mb-4' >
                                        Haftungsausschluss:
                                    </h5>
                                    <h6 className= "ff_bold mt-4" >
                                        Haftung für Inhalte
                                    </h6>
                                    <p>
                                        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                                    </p>
                                </div>


                                <div>
                                    <h6 className= "ff_bold mt-4" >
                                        Haftung für Links
                                    </h6>
                                    <p>
                                        Unsere Webseite enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                                    </p>
                                </div>

                                <div>
                                    <h6 className= "ff_bold mt-4" >
                                        Urheberrecht
                                    </h6>
                                    <p>
                                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                                    </p>
                                </div>


                                <div>
                                    <h6 className= "ff_bold mt-4" >
                                        Datenschutzerklärung
                                    </h6>
                                    <p>
                                        Unsere Datenschutzerklärung finden Sie hier: Datenschutzerklärung <br />
                                        Quelle: eRecht24.de
                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <CleanerFooter />
        </div>
    )
}

export default Imprint