// eslint-disable-next-line
import React,{useEffect,useState} from 'react'
import { Container } from 'react-bootstrap'
import { BsFacebook, BsYoutube, BsAndroid, BsArrowRight } from 'react-icons/bs'
import { AiOutlineInstagram, AiFillApple } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import logo4 from './assets/logo/logo3.png';
import { MdCardGiftcard } from 'react-icons/md'
import { HiUserCircle } from 'react-icons/hi'


const CleanerFooter = () => {
    const [year,setYear] = useState()
    useEffect(()=>{
        let date = new Date()
        setYear(date.getFullYear())
    },[year])
    return (
        <div>
            <div className='bg_primary' >
                <Container>
                    <div className='footer_top' >
                        <div className='row align-items-center py-3' >
                            <div className='col-12 col-md-4 py-2 py-lg-0 footer_change2'>
                                JM Dienst
                            </div>
                            <div className='col-12 col-md-4 py-2 py-md-0 d-flex align-items-center footer_change2'  >
                                Our channels
                                <Link to='/' className='a-href ms-3 social_icon'  > <BsFacebook />
                                </Link>
                                <Link to='/' className='a-href social_icon'>  <AiOutlineInstagram />
                                </Link>
                                <Link to='/' className='a-href social_icon' >  <BsYoutube />
                                </Link>
                            </div>
                            <div className='col-12 col-md-4 py-2 py-md-0 d-flex footer_change2 align-items-center'  >
                                Our apps <Link to='' className='a-href social_icon'><AiFillApple /></Link>
                                <Link to='' className='a-href social_icon'><BsAndroid /></Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='footer_bottom' >
                <Container>
                    <div className='row pb-4' >
                        <div className='col-lg-4 mb-4 mb-lg-0  footer_change' >
                            <div className='btn_width d-flex  justify-content-center align-items-center' >
                                <Link to='/'>
                                    <img src={logo4} className='logo_size3 ' alt='' />
                                </Link>
                            </div>
                            <ul className=' '>
                                <li className='my-3' >
                                    <button className='btn_white2 btn_width  cus_btn  '>
                                        <HiUserCircle className='me-2 fs-5' />
                                        Affiliate login</button>
                                </li>
                                <li className=' my-4' >
                                    <button className='cus_btn btn_width btn1 '>Become a partner<BsArrowRight className='ms-2 fs-5' /> </button>
                                </li>
                                <li className='my-3' >
                                    <button className='cus_btn btn_width btn1 '>
                                        Coupon <MdCardGiftcard className='ms-2 fs-5' /> </button>
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-md  mb-4 mb-md-0 text-center' >
                            About JM Dienst
                            <ul className='mt-3'>
                                <li className='text-center my-2 ' >
                                    <Link to='/about-us' className='a-href fs_9 color_white common'>About Us</Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/min-req' className='a-href fs_9 color_white common'>Our Minimun Requirments</Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>Quality</Link>
                                </li>
                                <li className='text-center my-2' >
                                    <Link to='/contact' className='a-href fs_9 color_white common'>Kontakt</Link>
                                </li>
                                {/* <li className='text-center my-2' >
                                    <Link to='/imprint' className='a-href fs_9 color_white common'>Impressum</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className='col-12 col-md mb-4 mb-md-0 text-center' >
                            Privacy
                            <ul className='my-3' >
                                {/* <li>
                                    <select className='fs_9 color_white loc ' >
                                        <option className='fs_9 ' value="1">Select locations</option>
                                        <option className='fs_9' value="2">Burlin</option>
                                        <option className='fs_9' value="3">Munich</option>
                                        <option className='fs_9' value="4">Hamburg</option>
                                        <option className='fs_9' value="4">Dresden</option>
                                        <option className='fs_9' value="4">Cologne</option>
                                    </select>
                                </li> */}
                                <li className='text-center my-2 ' >
                                    <Link to='/data-protection' className='a-href fs_9 color_white common'>Datenschutz </Link>
                                </li >
                                <li className='text-center my-2 ' >
                                    <Link to='/imprint' className='a-href fs_9 color_white common'>Impressum </Link>
                                </li >
                                {/* <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'> </Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>Hamburg</Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>Cologne</Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>Dresden</Link>
                                </li>
                                <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>All locations</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className='col-12 col-md mb-4 mb-md-0 text-center' >
                            Useful
                            <ul className='mt-3'>
                                <li className='text-center my-2 ' >
                                    <Link to='/help-center' className='a-href fs_9 color_white common'>Guide</Link>
                                </li >
                                <li className='text-center my-2' >
                                    <Link to='/quality-claim' className='a-href fs_9 color_white common'>Claim</Link>
                                </li >
                                {/* <li className='text-center my-2' >
                                    <Link to='/' className='a-href fs_9 color_white common'>Counselor</Link>
                                </li > */}
                            </ul>
                        </div>
                    </div>
                </Container>
                <div className='footer_end' >
                    <Container>
                        <div className='text-center py-3' style={{fontSize:"0.9rem" , opacity:'0.85'}} >
                            {/* <Link to='/' className='a-href fs_9 color_gray mx-2 common'>No data trading </Link>
                            <Link to='/' className='a-href fs_9 color_gray mx-2 common'> Conditions </Link>
                            <Link to='/' className='a-href fs_9 color_gray mx-2 common'>revocation</Link>
                            <Link to='/' className='a-href fs_9 color_gray mx-2 common'>data</Link>
                            <Link to='/' className='a-href fs_9 color_gray mx-2 common'> protection </Link>
                            <Link to='/' className='a-href fs_9 color_gray mx-2 common'>  imprint</Link> */}
                            Copyright © 2022 JM Dienst
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default CleanerFooter
