// eslint-disable-next-line
import React, { useEffect } from 'react'
import './css/style.css'
import { Container } from 'react-bootstrap'
import { BsFillTelephoneFill } from 'react-icons/bs'
import ImgCarousel from './carsouel';
import { GoCalendar } from 'react-icons/go';
import { TfiTime } from 'react-icons/tfi'
import { GiVacuumCleaner } from 'react-icons/gi'
import { MdOutlineNoTransfer } from 'react-icons/md'
import { TbVectorBezierCircle } from 'react-icons/tb'
import AllServices from './services';
import CleanerFooter from './footer';
import AdditionalInfo from './additionalinfo';
import ServicesCard from './services_card';
import CleanerNavbar from './navbar';
import Cookies from './cookies';


const LandingPage = () => {

  return (
    <div className='' >
      <CleanerNavbar />
      <Cookies />
      <div className='bg-img'>
        <Container>
          <div className='inner' >
            <div className='fs-1'>
              Herzlich Willkommen bei JM Dienst - Ihrer zuverlässigen Reinigungsfirma!
              <div className=' mt-3 fs-2'   >
                Sichern Sie sich jetzt Ihren Wunschtermin
              </div>
            </div>
            <button className='d-flex justify-content-center align-items-center cont_btn' >
            <a href="tel:042146888702" style={{color:'white',textDecoration: 'none'}}><BsFillTelephoneFill className='me-md-2' style={{ fontSize: "1rem" }} /> <span className='ms-2 mt-1' >042146888702</span></a>
            </button>
          </div>

        </Container>
      </div>
      <div>
        <div className='head2 fs-1' >
          Alle Leistungen von JM Dienst
        </div>
        <AllServices />
      </div>


      <div className='bg-white mb-5 pb-5 pt-4' >
        <Container className='my-5'>
          <div className='position-relative pt-4' >
            <ImgCarousel />
          </div>
          <div className='services' >
            <div className='usp__item ' >
              <div className='usp__icon' >
                <GoCalendar />
              </div>
              Zum Wunschtermin
            </div>
            <div className='usp__item left_border' >
              <div className='usp__icon' >
                <TfiTime style={{ height: '45px', width: "45px" }} />
              </div>
              Einzigartig & kurzfristig
            </div>
            <div className='usp__item left_border' >
              <div className='usp__icon' >
                <GiVacuumCleaner />
              </div>
              Wir stellen das Material zur Verfügung
            </div>
            <div className='usp__item left_border' >
              <div className='usp__icon' >
                <MdOutlineNoTransfer />
              </div>
              Keine Reisekosten
            </div>
            <div className='usp__item left_border' >
              <div className='usp__icon' >
                <TbVectorBezierCircle />
              </div>
              Inklusive aller Kosten
            </div>

          </div>
          <div className='bg_colr  ' >
            <Container className='pt-4' >
              <div className='fs-2 primary_color fw-semibold text-center mt-5' >
                Dienstleistungen
              </div>
              <div className='_cont_' >
                Reinigungsdienste werden täglich durchgeführt, um ein gewisses Maß an Sauberkeit und Hygiene in Ihrem Zuhause und Büro aufrechtzuerhalten.
              </div>
              <ServicesCard />
            </Container>
          </div>
        </Container>
        <div className='pt-4'>
          <div className='bg_colr pb-5' >
            <Container>
              <div className='pt-5' >
                <h1 className='text-center cont_block fs-1' >
                  Der Reinigungsservice mit
                  <br />
                  professionelles Reinigungspersonal

                </h1>
              </div>
              <div className='cont_block_para ' >
                <p className='mt-5' >
                  Sie suchen eine Reinigungskraft und möchten die Fenster in Ihrem Haus, Ihrer Wohnung oder Ihrem Büro reinigen lassen? Benötigen Sie eine Teppichreinigung oder möchten Sie die Polster auffrischen? Kein Problem.
                  JM ist die Lösung für alle, die eine professionelle Reinigungskraft für ihre Reinigung suchen. </p>
                <p className='mt-4' >
                  Fordern Sie ein kostenloses und unverbindliches Angebot für Ihre geplante Reinigung an! Und weil wir wissen, dass es immer schnell gehen muss, erfolgt die Reinigung zum Wunschtermin. Wir kümmern uns um alles von der Baureinigung über den Frühjahrsputz bis hin zur Endreinigung und entfernen Kalk, Schmutz und Dreck.   </p>
                <p className='mt-4' >
                  Das notwendige Reinigungsmaterial bringen wir selbst mit und unsere Preise beinhalten alle Kosten. Auch bei Sonderwünschen wie der Reinigung einer Messie-Wohnung, der Entfernung von Taubenkot oder der Bodenreinigung inklusive Versiegelung und Beschichtung sind wir Ihnen gerne behilflich.
                </p>
                <p className='mt-4' >
                  JM, das ist Ihr Reinigungszentrum für professionelle Reinigungskräfte, wann und wo immer Sie sie brauchen. Seit 2022 bieten wir Ihnen individuelle, auf Ihre Reinigungsanforderungen zugeschnittene Lösungen.
                </p>
                <p className='mt-4' >
                  Unsere Fachberater von JM verfügen über langjährige Erfahrung in der Gebäudereinigung und stehen Ihnen bundesweit täglich für Anfragen aller Art zur Verfügung. Kontaktieren Sie uns mit Ihren individuellen Wünschen für Ihre geplante Reinigung.
                </p>
                <p className='mt-4' >

                  Erleben Sie unseren kompetenten Kundenservice.
                </p>
              </div>
            </Container>
          </div>
        </div>
        <div>
          <Container className='mt-5 pt-4' >
            <AdditionalInfo />
          </Container>
        </div>
      </div>
      <div>
        <CleanerFooter />
      </div>
    </div>
  )
}

export default LandingPage
