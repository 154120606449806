import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import './css/style.css'
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import curve from './assets/images/curve.svg';
import logo2 from './assets/logo/logo2.png';
import logo1 from './assets/logo/logo3.png';
import QuoteModal from './quotemodal';
function ScrollHandler() {

  useEffect(() => {

   
    const handleScroll = () => {
      const element = document.getElementById('navbar');
      const element2 = document.getElementById('navbar2');

      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        if (element.classList.contains('NavB1'))
          element.classList.replace('NavB1', 'NavB');
        else element.classList.add('NavB');

        if (element2.classList.contains('Nav1'))
          element2.classList.replace('Nav1', 'Nav2');
        else element2.classList.add('Nav2');
      } else {
        if (element.classList.contains('NavB'))
          element.classList.replace('NavB', 'NavB1');

        if (element2.classList.contains('Nav2'))
          element2.classList.replace('Nav2', 'Nav1');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return null;
}

const CleanerNavbar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [year,setYear] = useState()
  useEffect(()=>{
    let date = new Date()
    setYear(date.getFullYear())
},[year])
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);

  };
  return (
    <div>
      <QuoteModal showModal={modalOpen} onClose={handleCloseModal} />
      <ScrollHandler />
      <div className='nav_top' >
        <Navbar className='NavB1 mb-0' id='navbar' style={{ zIndex: 1024 }} >
          <Container className='pb-0' >
            <Navbar.Brand href="" className='pb-0' >
              <img src={logo1} alt='' className='logo1 logo_size'/>
              <img src={logo2} alt='' className='logo2 logo_size' />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">

              <Nav className="ms-auto">
                <button  onClick={handleOpenModal} className='py-0 me-4 q_btn pe-2 circle_1' >
                  <HiDocumentText className='py-0 me-lg-1 ' style={{ width: "24px", height: "26px" }} /><span className='d_none' > fordern Sie ein Angebot an</span>
                </button>
                <button className='call_btn circle_1' >
                  <div className='d-flex justify-content-center fw-semibold align-items-center img1 fs-6 py-1' >
                    <a href="tel:042146888702" style={{textDecoration: 'none'}} className='primary_color'>
                      <BsFillTelephoneFill className='me-lg-2' style={{ fontSize: "1rem" }} /> <span className='ms-2 d_none' >042146888702</span></a>
                    </div>
                </button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className='position-relative' style={{marginTop:-1}}  >
          <Container className="p-0 d-flex align-items-center justify-content-between">
            <div className=' Nav1 pe-5 mt-0' id='navbar2'  > <span className='bg_color' >Professionelle Reinigung seit 2022 </span>
              <img src={curve} alt="" className='c_img ' />
            </div>
            <div className='sub' >.</div>
          </Container>
        </div>
      </div>

    </div >
  )
}

export default CleanerNavbar
