import React, { useState } from 'react';
import bgimage from './assets/images/bg2.jpg'
import bgimage2 from './assets/images/clean.jpg'
import bgimage3 from './assets/images/bg.jpg'
import { BsArrowRight, BsHouseCheck } from 'react-icons/bs'
import { Container } from 'react-bootstrap';
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { MdOutlineWindow, MdTableRestaurant, MdOutlineCleaningServices } from 'react-icons/md'
import { GiFloorPolisher, GiClothesline, GiHummingbird, GiSpookyHouse, GiVacuumCleaner, GiCampfire, GiWoodenPier } from 'react-icons/gi'
import { TbSofa, TbTruckLoading, TbSmoking } from 'react-icons/tb'
import { BsHouseDoor } from 'react-icons/bs'
import { VscSymbolConstant } from 'react-icons/vsc'
import { AiOutlineClose, AiOutlineClear } from 'react-icons/ai';


import './css/style.css'
import QuoteModal from './quotemodal';

function AllServices() {
    const [modalOpen, setModalOpen] = useState(false);
    const [checkservice, setservise] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);

    };
    const [activeTab, setActiveTab] = useState();
    const [mainTab, setmainTab] = useState(false);

    function changeBackground(imagedata, tab) {
        setActiveTab(tab);

        if (checkservice || window.innerWidth < 992) {
            console.log(checkservice)
            setModalOpen(true);

        } else {
            var element = document.getElementById("allservices");
            var cont_element = document.getElementById("cont1");
            cont_element.classList.remove('active');

            element.style.backgroundImage = "url('" + imagedata + "')";
        }
        if (activeTab === tab) {
            setmainTab(true)
        }

    }

    function showCont() {
        setservise(true);

        var service = document.getElementById("allservices");
        service.classList.add('show_btn');
        var content = document.getElementById("servicesCont");
        content.style.display = "none";
    }
    function closeCont() {
        setservise(false);

        var service2 = document.getElementById("allservices");
        var content2 = document.getElementById("servicesCont");
        if (checkservice || window.innerWidth > 992) {
            content2.style.display = "flex";
        } else {
            content2.style.display = "none";
        }
        service2.classList.remove('show_btn');
        service2.style.backgroundColor = "transparent";
    }

    return (
        <div>
            <QuoteModal showModal={modalOpen} onClose={handleCloseModal} />
            <Container>
                <div className='all_serv' id='allservices' >
                    <button className='btn_close cus_btn' onClick={() => closeCont()} ><AiOutlineClose /></button>
                    <div className='overlay' ></div>
                    <div className='services__content text-center d_lg_none' id='servicesCont' >
                        <div className={mainTab === true ? 'tab-content richtext ' : 'tab-content active richtext '} id='cont1'>
                            <h2 className='mb-4' >Ein Ansprechpartner für alles</h2>
                            <p className='mb-4' >
                                Egal was Sie gereinigt haben möchten, ob im Haus, Wohnung oder Büro.
                                Wir sind Ihr zuverlässiger Partner, wenn es um Grundreinigung, Fensterreinigung, Polsterreinigung und Teppichreinigung geht.
                                Mit unseren professionellen Teams sind wir in 3 Ländern und an über 120 Standorten vertreten.
                            </p>

                            <p className='mb-4' >
                                Wählen Sie die gewünschte Dienstleistung aus und senden Sie uns die notwendigen Daten und Informationen.
                                Wir erstellen Ihnen dann umgehend ein kostenloses Angebot für Ihre geplante Reinigung!
                            </p>

                            <p className='mb-4' >
                                Wir freuen uns auf Dich.
                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab1' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab1'>
                            <h2 className='mb-4' >Wohnungsreinigung</h2>
                            <p className='mb-4' >
                                Eine saubere Wohnung ist ein Ort des Wohlbefindens. Mit unserer Wohnungsreinigung sorgen wir dafür, dass Sie in einer sauberen und hygienischen Umgebung leben können. Wir reinigen alle Räume, Oberflächen, Böden und Sanitäranlagen gründlich und sorgfältig. Dabei verwenden wir nur
                                umweltfreundliche Reinigungsmittel und modernste Reinigungstechnologien, um beste Ergebnisse zu erzielen. </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab2' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab2'>
                            <h2 className='mb-4' >Fenster putzen</h2>
                            <p className='mb-4' >

                                Klare und saubere Fenster sind der Schlüssel zu einem strahlenden und schönen Zuhause oder Büro. Wir bieten Ihnen eine professionelle Fensterreinigung, die Ihre Fenster in kürzester Zeit wieder glänzend sauber macht. Unsere Fensterreinigung
                                umfasst alle Arten von Fenstern, auch schwer zugängliche und besonders hohe Fenster..
                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab3' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab3'>
                            <h2 className='mb-4' >Teppichreinigung</h2>
                            <p className='mb-4' >

                                Teppiche sind nicht nur eine Dekoration, sondern auch ein wichtiger Bestandteil des Raumklimas. Daher ist es wichtig, dass sie regelmäßig gereinigt werden. Mit unserer Teppichreinigung entfernen wir hartnäckige Flecken, Staub und Schmutz und sorgen dafür, dass Ihr Teppich wieder wie neu aussieht und riecht.
                            </p>

                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab4' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab4'>
                            <h2 className='mb-4' >Polsterreinigung</h2>
                            <p className='mb-4' >

                                Polstermöbel sind ein wichtiger Bestandteil Ihrer Einrichtung. Mit unserer Polsterreinigung entfernen wir Schmutz, Staub und Gerüche und sorgen dafür, dass Ihre Polstermöbel wieder wie neu aussehen und riechen. Wir verwenden nur umweltfreundliche Reinigungsmittel
                                und moderne Reinigungstechnologien, um beste Ergebnisse zu erzielen.
                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab5' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab5'>
                            <h2 className='mb-4' >Hausreinigung</h2>
                            <p className='mb-4' >

                                Ein sauberes Haus ist der Schlüssel zu einem angenehmen Wohngefühl. Mit unserer Hausreinigung sorgen wir dafür, dass Ihr Haus immer sauber und gepflegt ist. Wir reinigen alle Räume, Oberflächen, Böden und Sanitäranlagen gründlich und sorgfältig. Dabei verwenden wir nur umweltfreundliche Reinigungsmittel und modernste Reinigungstechnologien, um beste Ergebnisse zu erzielen.

                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab6' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab6'>
                            <h2 className='mb-4' >Baureinigung</h2>
                            <p className='mb-4' >
                                Eine Baustelle ist ein Ort des Chaos und Schmutzes. Mit unserer Baureinigung sorgen wir dafür, dass Ihre Baustelle wieder sauber und gepflegt wird. Wir entfernen alle Spuren des Bauens, inklusive Schmutz, Staub und Abfall. Wir bieten Ihnen eine professionelle und gründliche Baureinigung für Ihr Zuhause oder Gewerbeobjekt.
                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                        <div className={activeTab === 'tab7' ? 'tab-content active richtext' : 'tab-content richtext'} id='tab7'>
                            <h2 className='mb-4' >Entfernungsreinigung</h2>
                            <p className='mb-4' >

                                Eine Umzugsreinigung ist ein wichtiger Bestandteil des Umzugsprozesses. Mit unserer Umzugsreinigung sorgen wir dafür, dass Ihre alte Wohnung oder Ihr altes Büro sauber und gepflegt hinterlassen wird. Wir reinigen alle Räume, Oberflächen, Böden und Sanitäranlagen gründlich und sorgfältig. Dabei verwenden wir nur umweltfreundliche 
                                Reinigungsmittel und modernste Reinigungstechnologien, um beste Ergebnisse zu erzielen.
                            </p>
                            <p >
                                <button className='btn_white' onClick={handleOpenModal} >Fordern Sie ein Angebot an <BsArrowRight /></button>
                            </p>
                        </div>
                    </div>

                    <div className='services__boxes' >

                        <button onClick={() => changeBackground(bgimage, 'tab1')} className={activeTab === 'tab1' ? 'tab-btn active services__item' : 'tab-btn services__item'} >
                            <HiOutlineOfficeBuilding className='services__icon' />  Wohnungsreinigung</button>
                        <button onClick={() => changeBackground(bgimage2, 'tab2')} className={activeTab === 'tab2' ? 'tab-btn active services__item' : 'tab-btn services__item'}>
                            <MdOutlineWindow className='services__icon' /> Fenster putzen</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab3')} className={activeTab === 'tab3' ? 'tab-btn active services__item' : 'tab-btn services__item'}>
                            <GiFloorPolisher className='services__icon' /> Teppichreinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab4')} className={activeTab === 'tab4' ? 'tab-btn active services__item' : 'tab-btn services__item'}>
                            <TbSofa className='services__icon' /> Polsterreinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab5')} className={activeTab === 'tab5' ? 'tab-btn active services__item' : 'tab-btn services__item'} >
                            <BsHouseDoor className='services__icon' /> Hausputz</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab6')} className={activeTab === 'tab6' ? 'tab-btn active services__item' : 'tab-btn services__item'} >
                            <VscSymbolConstant className='services__icon' />Baureinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab7')} className={activeTab === 'tab7' ? 'tab-btn active services__item' : 'tab-btn services__item'} >
                            <TbTruckLoading className='services__icon' /> Entfernungsreinigung</button>
                        {/* only Show content when press All services */}
                        <button onClick={() => changeBackground(bgimage3, 'tab8')} className={activeTab === 'tab8' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <BsHouseCheck className='services__icon' /> Endreinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab9')} className={activeTab === 'tab9' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiClothesline className='services__icon' /> Frühjahrsputz</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab10')} className={activeTab === 'tab10' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiHummingbird className='services__icon' /> Taubenkot entfernen</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab11')} className={activeTab === 'tab11' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <MdTableRestaurant className='services__icon' /> Gastronomiereinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab12')} className={activeTab === 'tab12' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiSpookyHouse className='services__icon' /> Stark verschmutzte Wohnung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab13')} className={activeTab === 'tab13' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <MdOutlineCleaningServices className='services__icon' /> Teppichwäsche</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab14')} className={activeTab === 'tab14' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiVacuumCleaner className='services__icon' /> Konservator reinigeny</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab15')} className={activeTab === 'tab15' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <TbSmoking className='services__icon' /> Nikotingeruch entfernen</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab16')} className={activeTab === 'tab16' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiCampfire className='services__icon' /> Brandreinigung</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab17')} className={activeTab === 'tab17' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <AiOutlineClear className='services__icon' /> Ausmisten</button>
                        <button onClick={() => changeBackground(bgimage3, 'tab18')} className={activeTab === 'tab18' ? 'tab-btn active services__item show_service' : 'tab-btn services__item show_service'} >
                            <GiWoodenPier className='services__icon' /> Holzdeck reinigen</button>
                        {/* only Show content when press All services */}

                        <button onClick={() => showCont()} className=' hide_btn services__item primary_color'  >
                            <div className='d-flex align-items-center' >Alle Dienste <BsArrowRight className='ms-2' /></div></button>
                    </div>


                </div>
            </Container>
        </div>
    );
}

export default AllServices;
