/* eslint-disable no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './component/landing';
import { Routes ,Route } from 'react-router-dom';
import ContactUs from './component/contactus';
import Imprint from './component/imprint';
import AboutUs from './component/aboutUs';
import HelpCenter from './component/helpcenter';
import MinRequirment from './component/minReq';
import QualityClaim from './component/claim';
import DataProtection from './component/datenschutz';
function App() {
  return (
    <div className="">
      <Routes>
        <Route  path='/' element={<LandingPage/>} > </Route>
        <Route  path='/contact' element={<ContactUs/>} > </Route>
        <Route  path='/imprint' element={<Imprint/>} > </Route>
        <Route  path='/data-protection' element={<DataProtection/>} > </Route>
        <Route  path='/about-us' element={<AboutUs/>} > </Route>
        <Route  path='/help-center' element={<HelpCenter/>} > </Route>
        <Route  path='/min-req' element={<MinRequirment/>} > </Route>
        <Route  path='/quality-claim' element={<QualityClaim/>} > </Route>
      </Routes>
    </div>
  );
}

export default App;
