import React from 'react'
import contact from './assets/images/contact.svg'
import CleanerFooter from './footer'
import JMNavbar from './navbar2'
const ContactUs = () => {
    return (
        <>
            <JMNavbar />
            <div className="container my-5">
                <div className="row justify-content-center ">
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col-md-5 pe-sm-5 py-4">
                                <h3 className="heading mb-4">Let's talk about everything!</h3>
                               <p>
                                    <img src={contact} alt='' className='img_7' />
                                </p>
                            </div>
                            <div className="col-md-7 ps-sm-5 contact_Us py-4">
                                <h5 className='ff_bold' >
                                    Für Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                                </h5>
                                <h6>
                                    Joan Mohammad
                                </h6>
                                <h6>
                                    Ewald-Hanstein-Str. 7
                                </h6>
                                <h6>
                                    28755 Bremen
                                </h6>
                                <h6>
                                  <strong> Telefon: </strong>  <a href="tel:042146888702" style={{textDecoration: 'none'}} className='text-dark'>042146888702</a>
                                </h6>
                                <h6>
                                  <strong> E-Mail:</strong>  datenschutz@jmdienst.de
                                </h6>
                                <h6>
                                    <strong>
                                     Stand:   </strong>
                                     [01.05.2023]
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <CleanerFooter />
        </>
    )
}

export default ContactUs