/* eslint-disable no-unused-vars */
/* eslint-disable no-array-constructor */
// eslint-disable-next-line
import { Modal, Form, Accordion, Spinner, Toast } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import './css/style.css'
import { IoAddSharp } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { apiRequest } from './api/apirequest';

const QuoteModal = ({ showModal, onClose }) => {
    const initialFormData = {
        email: "",
        phone: "",
        company: "",
        gender: "",
        title: "",
        firstName: "",
        lastName: "",
        street: "",
        houseNo: "",
        postcode: "",
        city: "",
    };
    const [objectType, setObjectType] = useState('');
    const [floor, setFloor] = useState('');
    const [furnitureType, setFurnitureType] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [errorToast, seterrorToast] = useState(false);
    const [area, setarea] = useState('');
    const [reason, setreason] = useState('');
    const [degree, setdegree] = useState('');
    const [SelectedDate, setSelectedDate] = useState('')
    const [selectedImages, setSelectedImages] = useState([]);
    let ConvertedImage = [];
    const [description, setdescription] = useState('');
    const [errorsPost, seterrorsPost] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        company: '',
        gender: '',
        title: '',
        firstName: '',
        lastName: '',
        street: '',
        houseNo: '',
        postcode: '',
        city: ''
    });
    //-------------------------------------

    //-------------------------------------
        const handleClose = () => {
            onClose && onClose();
        };

    //-------------------------------------


    //-------------------------------------

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== "date") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    
    };

    const changeDate = (e) => {
        const date = e.target.value
        const parts = date.split('-');
        const year = parts[0];
        const day = parts[2];
        const month = parts[1];
        setSelectedDate(`${year}/${month}/${day}`);;
      };
      
    const handlePhoneChange = (phone) => {
        setFormData((prevData) => ({
            ...prevData,
            phone: phone
        }));
    };

    const initialCheckboxesState = [
        'Wohnungsreinigung',
        'Büroreinigung',
        'Fensterreinigung',
        'Teppichreinigung',
        'Polsterreinigung',
        'Hausreinigung',
        'Baureinigung',
        'Umzugsreinigung',
        'Endreinigung',
        'Frühjahrsputz',
        'Messie-Reinigung',
        'Taubenkot-Entfernung',
        'Gastronomiereinigung',
        'Wasserschadenreinigung',
        'Wintergartenreinigung',
        'Nikotingeruchentfernung',
        'Brandreinigung',
        'Entrümpelung',
        'Mattenreinigung',
        'Reinigung von Küchengeräten',
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);

    const handleCheckboxChange = (label) => {
        if (selectedLabels.includes(label)) {
            setSelectedLabels((prevLabels) => prevLabels.filter((prevLabel) => prevLabel !== label));
        } else {
            setSelectedLabels((prevLabels) => [...prevLabels, label]);
        }
    };

    //-------------------------------------

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form submission
        if (SelectedDate !== '' && formData.email !== '' && formData.firstName !== ''
            && formData.street !== '' && formData.city !== '' && formData.postcode !== '') {
            setIsLoading(true); // Set isLoading to true before starting the process
            const promises = selectedImages.map((imageUrl) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.crossOrigin = "anonymous";
                    img.src = imageUrl;
                    img.onload = () => {
                        const canvas = document.createElement("canvas");
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const context = canvas.getContext("2d");
                        context.drawImage(img, 0, 0);
                        const base64 = canvas.toDataURL("image/jpeg");
                        ConvertedImage.push({ img: base64 }); // Push converted image into the ConvertedImage array
                        resolve(base64);
                    };

                    img.onerror = () => {
                        reject(new Error("Failed to load image."));
                    };
                });
            });

            Promise.all(promises)
                .then((base64Images) => {
                    const body = new FormData();
                    body.append("type", "add_data");
                    body.append("table_name", "orders");
                    body.append("desired_date", SelectedDate);
                    body.append("object_type", objectType);
                    body.append("floor", floor);
                    body.append("furniture_type", furnitureType);
                    body.append("reason_cleaning", reason);
                    body.append("area_in_sq", area);
                    body.append("degree_of_polution", degree);
                    body.append("cleaning_request", JSON.stringify(selectedLabels));
                    body.append("description", description);
                    body.append("images", JSON.stringify(ConvertedImage));
                    body.append("billing_email", formData.email);
                    body.append("billing_phone", formData.phone);
                    body.append("company", formData.company);
                    body.append("gender", formData.gender);
                    body.append("profession", formData.title);
                    body.append("first_name", formData.firstName);
                    body.append("last_name", formData.lastName);
                    body.append("street", formData.street);
                    body.append("no", formData.houseNo);
                    body.append("postcode", formData.postcode);
                    body.append("city", formData.city);
                    apiRequest({ body })
                        .then(async (res) => {
                            console.log(res);
                            if (res.result === true) {
                                setShowToast(true);
                                setTimeout(() => {
                                onClose && onClose();
                                }, 1000);
                                getdata();
                                setSelectedDate('');
                                setObjectType('');
                                setFloor('')
                                setFurnitureType('');
                                setreason('');
                                setdegree('');
                                setSelectedLabels('')
                                setdescription('')
                                setSelectedImages([]);
                                setFormData(initialFormData)
                                ConvertedImage = []
                                setIsLoading(false);

                            } else{
                            setIsLoading(false);
                            }
                        })
                        .catch((error) => {
                            seterrorsPost(error)
                            seterrorToast(true);
                            setIsLoading(false);
                            console.error(error);

                        });


                })
                .catch((error) => {
                    console.error("Error converting images to base64:", error);
                    setIsLoading(false); // Set isLoading to false if there's an error converting images
                });
        } else {
            seterrorToast(true);

            console.log("Please fill required feild");
        }

    }
    //-------------------------------------
    const handleImageChange = (event) => {
        const files = event.target.files;

        // limit the number of selected images to 5
        if (selectedImages.length + files.length > 5) {
            alert('You can select up to 5 images.');
            return;
        }

        setSelectedImages(prevImages => [
            ...prevImages,
            ...Array.from(files).map(file => URL.createObjectURL(file))
        ]);
    };

    //-------------------------------------

    const handleRemoveImage = (index) => {
        const newSelectedImages = [...selectedImages];
        newSelectedImages.splice(index, 1);
        setSelectedImages(newSelectedImages);
    };

    const getdata = () => {
        const body = new FormData();
        body.append("type", "get_data");
        body.append("table_name", "orders");
        apiRequest({ body })
            .then(async (res) => {
                console.log(res);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });

    }
    useEffect(() => {

        getdata();
    }, [])

    //-------------------------------------


    return (
        <>
            <Modal show={showModal} onHide={handleClose}
                centered>
                <Modal.Header closeButton className='border-bottom-0' >
                    <Modal.Title className='fs-5 primary_color' >JM Dienst</Modal.Title>
                </Modal.Header>
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={2000} autohide >
                    <Toast.Header>
                        <strong className="me-auto text-primary">Success</strong>
                    </Toast.Header>
                    <Toast.Body>Service added successfully!</Toast.Body>
                </Toast>

                <Toast show={errorToast} onClose={() => seterrorToast(false)} delay={2000} autohide>
                    <Toast.Header>
                        <strong className="me-auto text-danger">Error</strong>
                    </Toast.Header>
                    <Toast.Body>{errorToast && "Please fill required feilds!"
                        }</Toast.Body>
                </Toast>

                <Form  >
                    <Modal.Body className='fs_9 req_modal' >
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className='fs-5 primary_color w-100 d-flex justify-content-center' >Normale Reinigung</div>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <div>
                                        <div className='' >
                                            <div className='light_color fs-5 mb-3' >
                                                Wunschtermin*
                                            </div>
                                            <div className='mb-3 hr_line_set'>
                                                <div className='hr_line' ></div>
                                                <div>
                                                    <span className='_line_'>
                                                        Optional
                                                    </span>
                                                </div>
                                                <div className='hr_line' ></div>

                                            </div>
                                            <div>
                                                <input type='date' required name='desired' className='form-control' onChange={changeDate} />
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='light_color fs-5 mt-3'>
                                                Objektdetails
                                            </div>
                                            <div className='mb-3 mt-2 hr_line_set'>
                                                <div className='hr_line' ></div>
                                                <div>
                                                    <span className='_line_'>
                                                        offen
                                                    </span>
                                                </div>
                                                <div className='hr_line' ></div>

                                            </div>
                                            <div>
                                                <div className='row align-items-center mt-3'>
                                                    <div className='col-5 primary_color'>
                                                      Objektart
                                                    </div>
                                                    <div className='col-7'>
                                                        <Form.Select value={objectType} onChange={(event) => setObjectType(event.target.value)} >
                                                            <option value=''>Bitte auswählen</option>
                                                            <option value='House'>Haus</option>
                                                            <option value='Apartment'>Wohnung</option>
                                                            <option value='Office'>Büro</option>
                                                            <option value='Practice'>Praxis</option>
                                                            <option value='Load'>Laden</option>
                                                            <option value='Restaurant'>Restaurant</option>
                                                        </Form.Select>
                                                    </div>
                                                </div>

                                                <div className='row align-items-center mt-3'>
                                                    <div className='col-5 primary_color'>
                                                       Stockwerk
                                                    </div>
                                                    <div className='col-7'>
                                                        <Form.Control type='text' value={floor} onChange={(event) => setFloor(event.target.value)} placeholder='zB Erdgeschoss oder 4. Stock' />
                                                    </div>
                                                </div>

                                                <div className='row align-items-center mt-3'>
                                                    <div className='col-5 primary_color'>
                                                       Art der Möbelierung
                                                    </div>
                                                    <div className='col-7'>
                                                        <Form.Select value={furnitureType} onChange={(event) => setFurnitureType(event.target.value)}>
                                                            <option value=''>Bitte auswählen</option>
                                                            <option value='Furnished'>Möbliert</option>
                                                            <option value='Partly Furnished'>Teilmöbliert</option>
                                                            <option value='Unfirnished'>Unmöbliert</option>
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='light_color fs-5 mt-3'>
                                                Reinigungsdetails
                                            </div>
                                            <div className='mb-3 mt-2 hr_line_set'>
                                                <div className='hr_line' ></div>
                                                <div>
                                                    <span className='_line_'>
                                                        offen
                                                    </span>
                                                </div>
                                                <div className='hr_line' ></div>

                                            </div>

                                            <div>
                                                <div className='row align-items-center mt-3' >
                                                    <div className='col-5 primary_color' >
                                                        Grund der Reinigung
                                                    </div>
                                                    <div className='col-7' >
                                                        <Form.Select value={reason} onChange={(event) => setreason(event.target.value)}>

                                                            <option value="">Bitte wählen</option>
                                                            <option value="General Cleaning">Normale Reinigung</option>
                                                            <option value="Final Cleaning">Endreinigung</option>
                                                            <option value="Moving Cleaning">Umzugsreinigung</option>
                                                            <option value="After Craftsman">Nach Handwerker</option>
                                                            <option value="Spring Cleaning">Frühjahrsputz</option>
                                                            <option value="Construction Cleaning">Baureinigung</option>
                                                            <option value="Heavily Polluted Object">Stark verschmutztes Objekt</option>
                                                            <option value="After Water Damage">Nach Wasserschaden</option>
                                                            <option value="Messie Apartment">Messie Wohnung</option>
                                                            <option value="Remove Pigeon Droppings">Taubenkot entfernen</option>
                                                            <option value="Fire Cleaning">Brandreinigung</option>
                                                            <option value="Wood Terrace Cleaning">Holzterrasse reinigen</option>
                                                            <option value="Gastronomy Cleaning">Gastronomiereinigung</option>
                                                            <option value="Remove Nicotine">Nikotingeruch entfernen</option>
                                                            <option value="Clearing and Disposal">Entrümpelung und Entsorgung</option>
                                                            <option value="Disinfecting Cleaning">Desinfektionsreinigung</option>
                                                            

                                                        </Form.Select>
                                                    </div>

                                                </div>

                                                <div className='row align-items-center mt-3' >
                                                    <div className='col-5 primary_color' >
                                                        Fläche in qm
                                                    </div>
                                                    <div className='col-7' >
                                                        <Form.Control type="number" placeholder="0" value={area} onChange={(event) => setarea(event.target.value)} />
                                                    </div>
                                                </div>
                                                <div className='row align-items-center mt-3' >
                                                    <div className='col-5 primary_color' >
                                                        Verschmutzungsgrad
                                                    </div>
                                                    <div className='col-7' >
                                                        <Form.Select value={degree} onChange={(event) => setdegree(event.target.value)}>

                                                            <option value="">Bitte auswählen</option>
                                                            <option value="Light">Leicht</option>
                                                            <option value="Normal">Normal</option>
                                                            <option value="Strong">Stark</option>
                                                            <option value="Extreme">Extrem</option>
                                                        </Form.Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='' >
                                            <div className='light_color fs-5 mt-3'>
                                                Reinigungsanfragen
                                            </div>
                                            <div className='mb-3 mt-2 hr_line_set'>
                                                <div className='hr_line' ></div>
                                                <div>
                                                    <span className='_line_'>
                                                        Optional
                                                    </span>
                                                </div>
                                                <div className='hr_line' ></div>

                                            </div>
                                            <div className='clean_req'>
                                                {initialCheckboxesState.map((label, index) => (
                                                    <div key={index}>
                                                        <Form.Check
                                                            className='text_ellipsis'
                                                            type="checkbox"
                                                            checked={selectedLabels.includes(label)}
                                                            onChange={() => handleCheckboxChange(label)}
                                                            label={label}
                                                        />
                                                    </div>
                                                ))}

                                            </div>
                                        </div>

                                        <div>
                                            <div className='' >
                                                <div className='light_color fs-5 mt-3'>
                                                    Weitere Informationen
                                                </div>
                                                <div className='mb-3 mt-2 hr_line_set'>
                                                    <div className='hr_line' ></div>
                                                    <div>
                                                        <span className='_line_'>
                                                            Optional
                                                        </span>
                                                    </div>
                                                    <div className='hr_line' ></div>

                                                </div>
                                            </div>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Beschreiben Sie Ihre geplante Reinigung"
                                                rows={3}
                                                style={{ resize: "vertical" }}
                                                value={description}
                                                onChange={(event) => setdescription(event.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <div className='' >
                                                <div className='light_color fs-5 mt-3'>
                                                    Bilder zum Reinigen
                                                </div>
                                                <div className='mb-3 mt-2 hr_line_set'>
                                                    <div className='hr_line' ></div>
                                                    <div>
                                                        <span className='_line_'>
                                                            Optional
                                                        </span>
                                                    </div>
                                                    <div className='hr_line' ></div>

                                                </div>
                                            </div>
                                            <div className='position-relative border px-3 py-3 mt-3 '>
                                                <div className='d-flex align-items-center ff_light mb-3'>
                                                    <div
                                                        className='rounded-1 d-flex me-3 align-items-center justify-content-center fs-3 '
                                                        style={{ width: '40px', border: '1px dashed', height: '40px' }}
                                                    >
                                                        <IoAddSharp />
                                                    </div>
                                                    Fotos hinzufügen
                                                </div>
                                                <div className='pic_limit'>{selectedImages.length} von 5</div>
                                                <Form.Control
                                                    className='modalpic'
                                                    type='file'
                                                    accept='image/*'
                                                    multiple
                                                    onChange={handleImageChange}
                                                />
                                                <div className='pic_clean mb-4' >
                                                    {selectedImages.map((image, index) => (
                                                        <div key={index} className="position-relative">
                                                            <img src={image} alt={index} className='rounded-2' />
                                                            <button type='button'
                                                                className="btn-close position-absolute top-0 end-0 m-2 bg-light rounded-circle"
                                                                onClick={() => handleRemoveImage(index)}
                                                            />
                                                        </div>

                                                    ))}</div>

                                            </div>

                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={['1']} alwaysOpen>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>

                                    <div className='fs-5 primary_color w-100 d-flex justify-content-center' >Name and Address</div>

                                </Accordion.Header>
                                <Accordion.Body  >
                                    <div className='name_address'  >
                                        <div className='' >
                                            <div className='light_color fs-5 mb-3' >
                                                Rechnungsadresse
                                            </div>
                                            <div className='mb-3 hr_line_set'>
                                                <div className='hr_line' ></div>
                                                <div>
                                                    <span className='_line_'>
                                                        Optional
                                                    </span>
                                                </div>
                                                <div className='hr_line' ></div>

                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    placeholder="E-Mail-Addresse*"
                                                    className="form-control mb-3"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <PhoneInput
                                                    country={'us'}
                                                    value={formData.phone}
                                                    onChange={handlePhoneChange}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Unternehmen"
                                                    className="form-control mt-3"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                />
                                                <div className="row gx-2">
                                                    <div className="col-sm-6 mt-3">
                                                        <select
                                                            className="form-select"
                                                            name="gender"
                                                            value={formData.gender}
                                                            onChange={handleChange}>
                                                            <option value="">Bitte auswählen</option>
                                                            <option value="Herr">Herr</option>
                                                            <option value="Frau">Frau</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <select
                                                            className="form-select"
                                                            name="title"
                                                            value={formData.title}
                                                            onChange={handleChange}
                                                        // onChange={handleTitleChange}
                                                        >
                                                            <option value="">Bitte auswählen</option>
                                                            <option value="dr">DR</option>
                                                            <option value="prof">Prof</option>
                                                            <option value="prof. Dr.">Prof. DR.</option>
                                                            <option value="like">wie</option>
                                                            <option value="Dr. like">DR. wie</option>
                                                            <option value="prof. Dr. like">Prof. Dr. wie</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row gx-2">
                                                    <div className="col-sm-6 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="Vorname*"
                                                            className="form-control"
                                                            name="firstName"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="Familienname, Nachname*"
                                                            className="form-control"
                                                            name="lastName"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row gx-2">
                                                    <div className="col-sm-8 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="Straße*"
                                                            className="form-control"
                                                            name="street"
                                                            value={formData.street}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="Nummer"
                                                            className="form-control"
                                                            name="houseNo"
                                                            value={formData.houseNo}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row gx-2">
                                                    <div className="col-sm-4 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="PLZ*"
                                                            className="form-control"
                                                            name="postcode"
                                                            value={formData.postcode}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-sm-8 mt-3">
                                                        <input
                                                            type="text"
                                                            placeholder="Stadt*"
                                                            className="form-control"
                                                            name="city"
                                                            value={formData.city}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>


                    </Modal.Body>
                    <Modal.Footer className='border-top-0 pt-1' >
                        <button
                            className="bg_primary cus_btn text-white w-100 p-2 text-center mt-4 rounded-5"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <Spinner animation="border" variant="light" size="sm" />
                            ) : (
                                "Angebot anfordern"
                            )}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default QuoteModal
