import React from 'react'
import { Container } from 'react-bootstrap'
import JMNavbar from './navbar2'
import './css/style.css'
import CleanerFooter from './footer'

const AboutUs = () => {
    return (
        <div>
            <JMNavbar />
            <Container className='my-5 about_list' >
                <h3 className='ff_bold '  >
                    Über uns:
                </h3>
                <p>
                    Willkommen bei JMDienst, Ihrem professionellen Reinigungsunternehmen mit Sitz in Bremen. Seit unserer Gründung im Jahr 2023 sind wir bestrebt,
                    unseren Kunden erstklassige Reinigungsdienstleistungen anzubieten. Unser erfahrenes Team von qualifizierten Reinigungskräften setzt modernste Techniken und hochwertige Reinigungsmittel ein, um ein Höchstmaß an Sauberkeit und Hygiene zu gewährleisten.
                </p>
                <p>
                    Bei JMDienst liegt unser Fokus auf maßgeschneiderten Lösungen, um die individuellen Bedürfnisse unserer Kunden zu erfüllen. Wir bieten ein breites Spektrum an Reinigungsdienstleistungen an, darunter Wohnungsreinigung, Fensterreinigung, Teppichreinigung,
                    Hausreinigung, Baureinigung, Umzugsreinigung, Endreinigung und vieles mehr. Unser Ziel ist es, Ihnen eine saubere und hygienische Umgebung zu schaffen, in der Sie sich wohl und entspannt fühlen können.
                </p>
                <h5 className='mt-4 ff-bold' ><strong>Warum JMDienst wählen?</strong></h5>
                <ol className='' >
                    <li>
                        <strong>Professionelles Team: </strong>Unser geschultes Team besteht aus erfahrenen Reinigungskräften, die ihr Handwerk verstehen. Sie sind kompetent, zuverlässig und arbeiten mit großer Sorgfalt, um optimale Ergebnisse zu erzielen.
                    </li>
                    <li>
                        <strong>Höchste Qualität:  </strong>Wir legen großen Wert auf Qualität und setzen nur hochwertige Reinigungsmittel und -geräte ein. Unser Ziel ist es, Ihnen eine gründliche und effektive Reinigung zu bieten, bei der kein Detail übersehen wird.</li>
                    <li>
                        <strong>Kundenzufriedenheit: </strong>Ihre Zufriedenheit hat für uns oberste Priorität. Wir gehen auf Ihre individuellen Wünsche und Bedürfnisse ein und streben danach, Ihre Erwartungen zu übertreffen. Ihre positive Erfahrung mit unseren Dienstleistungen ist unser größtes Anliegen.
                    </li>
                    <li>
                        <strong>Flexibilität und Zuverlässigkeit: </strong>Wir passen uns Ihren Terminplänen an und sind stets pünktlich vor Ort. Unsere flexiblen Reinigungspläne ermöglichen es Ihnen, den Service zu wählen, der am besten zu Ihnen passt</li>

                    <li>
                        <strong>Nachhaltigkeit:</strong>Wir passen uns Ihren Terminplänen an und sind stets pünktlich vor Ort. Unsere flexiblen Reinigungspläne ermöglichen es Ihnen, den Service zu wählen, der am besten zu Ihnen passt</li>
                    <li>
                        <strong>Nachhaltigkeit:</strong>Wir setzen auf umweltfreundliche Reinigungsmethoden und Produkte, um die Auswirkungen auf die Umwelt zu minimieren. Unsere Reinigungstechniken sind effektiv und schonend zugleich, um eine gesunde und nachhaltige Reinigung zu gewährleisten.</li>
                </ol>
                <section>
                    <p className='mt-4' >Entscheiden Sie sich für JMDienst und erleben Sie professionelle Reinigungsdienstleistungen,
                        die Ihren Ansprüchen gerecht werden. Kontaktieren Sie uns noch heute, um Ihre Reinigungsanforderungen zu besprechen und ein maßgeschneidertes Angebot zu erhalten. Unser engagiertes Team freut sich darauf, Ihnen einen makellosen Reinigungsservice zu bieten, der Ihren Erwartungen gerecht wird.</p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Referenzen und Erfolgsgeschichten:
                            </strong>
                        </h6  >
                        Wir sind stolz darauf, eine Vielzahl von zufriedenen Kunden bedienen zu dürfen. Lesen Sie einige unserer Erfolgsgeschichten und Referenzen auf unserer Homepage. Hier erfahren Sie, wie wir unseren Kunden in der Vergangenheit geholfen haben und was sie über unsere Arbeit sagen.

                    </p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Kundenorientierung:
                            </strong>
                        </h6  >
                        Wir setzen uns dafür ein, dass unsere Kunden immer zufrieden sind. Deshalb bieten wir flexible Servicezeiten, um sicherzustellen, dass wir Ihre Bedürfnisse erfüllen können. Wir passen unsere Dienstleistungen an Ihre spezifischen Anforderungen an, um sicherzustellen, dass Sie nur das bezahlen, was Sie wirklich brauchen.

                    </p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Qualitätsgarantie:
                            </strong>
                        </h6  >
                        Wir sind so zuversichtlich in unsere Arbeit, dass wir eine 100% Zufriedenheitsgarantie bieten. Wenn Sie aus irgendeinem Grund nicht mit unserer Arbeit zufrieden sind, werden wir alles tun, um das Problem zu lösen und sicherzustellen, dass Sie vollständig zufrieden sind.

                    </p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Umweltfreundlichkeit:
                            </strong>
                        </h6  >
                        Wir verwenden nur umweltfreundliche Reinigungsmittel und Technologien, um sicherzustellen, dass unsere Arbeit sowohl sicher als auch nachhaltig ist. Wir setzen uns für eine umweltbewusste Reinigung ein, die den Planeten und die Gesundheit unserer Kunden schützt.

                    </p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Preisgestaltung:
                            </strong>
                        </h6  >
                        Wir bieten faire Preise und transparente Abrechnungen. Wir glauben, dass gute Qualität nicht unbedingt teuer sein muss, deshalb sind unsere Preise fair und wettbewerbsfähig. Wir bieten Ihnen eine detaillierte Kostenaufstellung, damit Sie genau wissen, was Sie bezahlen und wofür.

                    </p>
                    <p>
                        <h6 className='ff-bold' >
                            <strong>
                                Vertraulichkeit:
                            </strong>

                        </h6  >
                        Wir verstehen, dass Diskretion in manchen Fällen von höchster Bedeutung ist. Deshalb legen wir großen Wert auf die Vertraulichkeit unserer Arbeit und sorgen dafür, dass alle unsere Mitarbeiter hohen ethischen Standards entsprechen.
                        Kontaktieren Sie uns jetzt, um mehr darüber zu erfahren, wie wir Ihnen helfen können, saubere und hygienische Räumlichkeiten zu schaffen. Wir freuen uns darauf, von Ihnen zu hören!

                    </p>
                </section>
            </Container>
            <CleanerFooter />
        </div>
    )
}

export default AboutUs