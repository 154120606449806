import axios from 'axios'
// const API_URL = 'https://locatestudent.com/cleaner/api.php'
const API_URL = 'https://jmdienst.de/api/api.php'
export const body = new FormData()
export const apiRequest = async ({ body }) => {
  // ** Store Vars
  return await axios
    .post(API_URL, body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      return err
    })
}
