import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import card1 from './assets/images/card1.png';
import card2 from './assets/images/card2.png';
import './css/style.css'
import card3 from './assets/images/card3.png';
import Carousel from 'react-bootstrap/Carousel';

import card4 from './assets/images/card4.png';
import QuoteModal from './quotemodal';

const ServicesCard = () => {
    const [index, setIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const handleOpenModal = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
  
    };
    const CarouselSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const RequestBtn = () => {
        return (
            <Button onClick={handleOpenModal} className='card_btn' >Fordern Sie ein Angebot an
            </Button>
        )
    }

    return (
        <div>
            <QuoteModal showModal={modalOpen} onClose={handleCloseModal} />

            <Carousel activeIndex={index} onSelect={CarouselSelect} interval={null} className='car_none car_cont' >
                <Carousel.Item className='car_cont' >
                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card1} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Allgemeine Reinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Eine Reinigungskraft ist für die gesamte Grundreinigung in und um die Einrichtung oder das Bürogebäude verantwortlich.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>
                </Carousel.Item>

                <Carousel.Item className='car_cont'>
                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card2} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Fenster putzen</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Möchten Sie Ihren Fenstern neues Leben einhauchen? Dann suchen Sie nicht weiter und nehmen Sie gleich Kontakt mit Kam Kaj auf! Unsere hauseigenen Fachleute sind immer bereit, Ihre Reinigungsanforderungen zu erfüllen.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>

                </Carousel.Item>

                <Carousel.Item className='car_cont'>

                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card3} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Teppichreinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Unsere erfahrenen Mitarbeiter im Haus wissen, wie man tiefsitzenden Schmutz, hartnäckige Flecken und Bakterien aus den Teppichen entfernt.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>
                </Carousel.Item>
                
                <Carousel.Item className='car_cont' >
                    <div >
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card4} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Polsterreinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                              Wenn es um die Pflege der Wohn- oder Büromöbel geht, ist die Polsterreinigung ein absolutes Muss.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>
                </Carousel.Item>
            </Carousel>
            <div className='card_none' >
                <div className='services_card ' >
                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card1} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Allgemeine Reinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Eine Reinigungskraft ist für die gesamte Grundreinigung in und um die Einrichtung oder das Bürogebäude verantwortlich.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>

                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card2} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Fenster putzen</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Möchten Sie Ihren Fenstern neues Leben einhauchen? Dann suchen Sie nicht weiter und nehmen Sie gleich Kontakt mit Kam Kaj auf! Unsere hauseigenen Fachleute sind immer bereit, Ihre Reinigungsanforderungen zu erfüllen.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>

                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card3} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Teppichreinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                               Unsere erfahrenen Mitarbeiter im Haus wissen, wie man tiefsitzenden Schmutz, hartnäckige Flecken und Bakterien aus den Teppichen entfernt.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>

                    <div>
                        <div className='' >
                            <div className='card_cont' >
                                <img src={card4} alt='' className='card_img' />
                                <div className='inner_cont' >
                                    <div className='d-flex justify-content-center align-items-center h-100 w-100'>Polsterreinigung</div>
                                </div>
                            </div>
                            <div className='card_discp' >
                              Wenn es um die Pflege der Wohn- oder Büromöbel geht, ist die Polsterreinigung ein absolutes Muss.
                            </div>

                        </div>
                        <RequestBtn />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ServicesCard
